import { memo } from 'react';
import cx from 'classnames';
import Card from '@components/card';
import CardV2 from '@components/cardV2';
import PromoBanner from '@components/promoBanner';
import { Variants } from '@constants/dashboards';

const Row = ({ fields, position = -1, page, sectionType }) => {
  /**
   * position represents position of row
   * If rows with position 1,2 & 3 of section 1 & 2 have any images, load it on priority for better user experience
   */
  const { variant, cardItems, informationModules, direction } = fields;

  /// depending on variant, render a row
  const mappedVariant = Variants.find(varnt => varnt.value === variant);
  const centeredRows = mappedVariant?.type === 'centeredRows';
  const normalRow = mappedVariant?.type === 'normalRow';

  const cardTextAlignment =
    mappedVariant?.type === 'normalRow' &&
    page === 'Home Page' &&
    sectionType === 'portraitIconCard';

  let cards = mappedVariant?.cards || 1;

  const fullWidthCard = mappedVariant?.term === '100';
  const isFiftyFifty = mappedVariant?.term === '50-50';
  if (isFiftyFifty) {
    cards = 4;
  }
  if (normalRow || centeredRows) {
    const rowClass = cx(
      'justify-center',
      {
        'w-full flex flex-wrap my-20': centeredRows
      },
      {
        'gap-7 mx-6 lg:mx-10 first:mt-20 m-6 mt-20 last:mb-20 grid grid-cols-1':
          normalRow
      },
      normalRow && {
        'md:grid-cols-1': cards === 1,
        'md:grid-cols-2': cards === 2,
        'md:grid-cols-3': cards === 3,
        'md:grid-cols-2 lg:grid-cols-4': cards === 4,
        'md:grid-cols-5': cards === 5,
        'md:grid-cols-6': cards === 6
      }
    );

    // render a centered cards row component here
    return (
      <div className={rowClass}>
        {cardItems.map(cardItem => (
          <Card
            key={cardItem.sys.id}
            position={position}
            cardTextAlignment={cardTextAlignment}
            fullWidthCard={fullWidthCard}
            isFiftyFifty={isFiftyFifty}
            cards={cards}
            normalRow={normalRow}
            centeredRows={centeredRows}
            fields={cardItem.fields}
          />
        ))}
      </div>
    );
  } else if (mappedVariant?.type === 'promoBanner') {
    return (
      <>
        {cardItems.map(cardItem => (
          <PromoBanner
            key={cardItem.sys.id}
            position={position}
            fields={cardItem.fields}
            sys={cardItem.sys}
          />
        ))}
      </>
    );
  } else if (informationModules?.length) {
    return (
      <div className='mx-6 lg:mx-10'>
        <div
          className={`${
            direction === 'Horizontal' ? 'flex' : 'flex-none'
          } justify-center grid-cols-1 gap-10 px-0 mb-10 md:grid-cols-2 md:px-0 xl:px-0`}
        >
          {informationModules?.map(module => (
            <CardV2 key={module.id} position={position} cardData={module} />
          ))}
        </div>
      </div>
    );
  }
};

export default memo(Row);
