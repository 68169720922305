import React, { memo, Fragment } from 'react';
import cx from 'classnames';
import contentTypeRenderer from '@components/contentTypeRenderer';
import { getHeroSectionSize } from '@utils/common';
import { LEFT } from '@constants/index';

/**
 * @class
 * @param {Object} sectionData Data for the hero banner
 *
 * @returns Short Hero Banner Component
 */

const ShortHeroBanner = ({ sectionData, priority }) => {
  const {
    sectionId,
    backgroundImage,
    contentItems,
    darkeningLayerOpacity,
    heroHeight = []
  } = sectionData || {};

  const heroSectionSize = getHeroSectionSize(heroHeight?.[0]);
  const sectionClasses = cx(
    heroSectionSize,
    '!bg-cover',
    'relative',
    'border-b-0',
    '-mb-px',
    'flex',
    'md:bg-auto',
    'text-white',
    'w-full',
    'px-12 py-10',
    '!bg-no-repeat !bg-center lg:!bg-cover'
  );

  const bgUrl = backgroundImage
    ? `https:${backgroundImage?.fields?.asset?.[0]?.fields.file.url}?fm=jpg&fl=progressive`
    : '';
  const opacityRange = heroHeight?.fields?.darkeningLayerOpacity
    ? heroHeight?.fields?.darkeningLayerOpacity
    : darkeningLayerOpacity;
  return (
    <>
      <section
        style={{ background: `url(${bgUrl})` }}
        className={sectionClasses}
        data-testid='ShortHeroBanner'
        id={sectionId || ''}
      >
        <div
          style={{
            backgroundColor: `rgba(0, 0, 0, ${opacityRange})`,
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
          }}
          className={`hidden md:block ${heroSectionSize}`}
        ></div>
        <div className='flex lg:w-3/5 z-10'>
          <div className='hidden lg:flex'>
            {contentItems?.map(contentItem => (
              <Fragment key={contentItem?.sys?.contentType?.sys?.id}>
                {contentTypeRenderer(
                  contentItem?.sys?.contentType?.sys?.id,
                  contentItem?.fields,
                  LEFT,
                  priority
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </section>

      {contentItems.length > 0 && (
        <section className='bg-black block lg:hidden'>
          {contentItems?.map(contentItem => (
            <Fragment key={contentItem?.sys?.contentType?.sys?.id}>
              {contentTypeRenderer(
                contentItem?.sys?.contentType?.sys?.id,
                contentItem?.fields,
                LEFT,
                priority
              )}
            </Fragment>
          ))}
        </section>
      )}
    </>
  );
};

export default memo(ShortHeroBanner);
