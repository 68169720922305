import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import cx from 'classnames';
import { submitQuote } from '@services/api/quote/quoteService';
import PropTypes from 'prop-types';
import Button from '@components/button';
import formItemRender from '@components/formItemRender';
import Loader from '@components/loader';
import RichText from '@components/richtext';
import { RowVariant, DefaultFormSuccessMessageHeading } from '@constants/index';

/**
 * @class
 * @param {Object} formData Data for Form elements.
 *
 * @returns Form Component
 */

const Form = ({ formData }) => {
  const {
    heading,
    subHeading,
    formItemRows,
    formSuccessMessageHeading,
    formSuccessMessageSubheading
  } = formData;
  const RowClasses = variant =>
    cx('grid grid-cols-1', {
      'lg:grid-cols-2': RowVariant.TWOFORMITEMS === variant
    });
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit'
  });
  const onSubmit = async formData => {
    setLoading(true);
    const response = await submitQuote(formData);
    if (response.message.includes('Bad')) {
      toast.error(response.detail);
    } else {
      toast.success(response.detail);
      setSubmit(true);
    }
    setLoading(false);
  };

  Form.PropTypes = {
    formData: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subHeading: PropTypes.string.isRequired,
      formItemRows: PropTypes.array.isRequired,
      formSuccessMessageHeading: PropTypes.string.isRequired,
      formSuccessMessageSubheading: PropTypes.string
    }).isRequired
  };

  const containerClasses = cx('py-6 xl:px-16 xl:py-10', {
    'h-screen px-10 py-16 justify-center flex flex-col': submit
  });

  const showForm = () => (
    <>
      {loading ? (
        <Loader variant='page-spinner' className='h-24 w-24 ' />
      ) : (
        <>
          <h1 className='text-[2rem]'>{heading}</h1>
          <div className='font-proLight py-2 text-xl'>
            <RichText text={subHeading} />
          </div>
          <h2 className='pt-5 pb-2 text-sm'>*Required fields</h2>
          <form onSubmit={handleSubmit(onSubmit)} className='py-2'>
            {formItemRows?.map(({ fields: row, sys: rowObj }) => (
              <div className={RowClasses(row?.variant)} key={rowObj?.id}>
                {row
                  ? row?.formItemSection.map(
                      ({ fields: formItem, sys: itemType }) => (
                        <div key={itemType?.id} className='px-2'>
                          {formItemRender(
                            itemType?.contentType?.sys?.id,
                            formItem,
                            register,
                            errors
                          )}
                        </div>
                      )
                    )
                  : null}
              </div>
            ))}
            <div className='pt-3 font-BallingerMonoMd leading-[14px] tracking-wide'>
              <Button
                type='submit'
                variant='primary'
                data-testid='submit'
                className={cx('col-end-11 uppercase w-full')}
              >
                Submit
              </Button>
            </div>
          </form>
        </>
      )}
    </>
  );

  return (
    <div className={containerClasses} data-testid='form'>
      <div
        className={cx('w-full bg-white text-black p-6 lg:p-10 font-roman', {
          'flex justify-center align-middle h-[600px]': loading
        })}
      >
        {submit ? (
          <>
            <h1 className='text-5xl py-10'>
              {formSuccessMessageHeading ?? DefaultFormSuccessMessageHeading}
            </h1>
            {formSuccessMessageSubheading && (
              <RichText
                text={formSuccessMessageSubheading}
                classNames={{ container: 'font-proLight' }}
              />
            )}
          </>
        ) : (
          <>{showForm()}</>
        )}
      </div>
    </div>
  );
};

export default Form;
